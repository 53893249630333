//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { createRequest } from "@/common/lib/polkadot-provider/command/service-request";
import { createRequestFee } from "@debionetwork/polkadot-provider";
import errorMessage from "@/common/constants/error-messages";
import { errorHandler } from "@/common/lib/error-handler";
import { getLocations, getStates, getCities, getCategories } from "@/common/lib/api";
import { alertIcon } from "@debionetwork/ui-icons";
import Web3 from "web3";
export default {
  name: "AgreementDialog",
  props: {
    show: Boolean
  },
  data: () => ({
    alertIcon,
    errorMessage,
    currencyList: ["DBIO"],
    currencyType: "DBIO",
    agree: false,
    amount: "",
    dialogAlert: false,
    isLoading: false,
    transactionStep: "",
    txWeight: 0,
    showError: false,
    errorTitle: "",
    errorMsg: "",
    country: "",
    state: "",
    city: "",
    category: "",
    states: [],
    cities: [],
    categories: [],
    countries: [],
    noState: false,
    noCity: false,
    countryName: "",
    showErrorAlert: false
  }),
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      pair: state => state.substrate.wallet,
      setCountry: state => state.lab.country,
      setState: state => state.lab.region,
      setCity: state => state.lab.city,
      setCategory: state => state.lab.category,
      lastEventData: state => state.substrate.lastEventData,
      walletBalance: state => state.substrate.walletBalance
    }),
    amountRules() {
      return [val => !!val || this.errorMessage.REQUIRED, val => !!/^[0-9]\d*(\.\d{0,9})?$/.test(val) || this.errorMessage.INPUT_CHARACTER("Numbers (e.g. 20.005)"), val => val > 0 || this.errorMessage.AMOUNT];
    },
    disable() {
      const {
        country,
        state,
        city,
        category
      } = this;
      return !(country && state && city && category && this.amount && this.agree && this.amount > 0);
    }
  },
  async mounted() {
    await this.getCountries();
    await this.getServiceCategory();
    const txWeight = await createRequestFee(this.api, this.pair, this.country, this.state, this.city, this.category);
    this.txWeight = Web3.utils.fromWei(String(txWeight.partialFee), "ether");
  },
  watch: {
    lastEventData() {
      if (this.lastEventData) {
        if (this.lastEventData.method === "ServiceRequestCreated") {
          this.isLoading = false;
          this.dialogAlert = true;
          this.$emit("click");
        }
      }
    }
  },
  methods: {
    closeDialog() {
      this.$emit("close");
    },
    toSelectService() {
      this.$emit("fetch");
    },
    async getServiceCategory() {
      const data = await getCategories();
      this.categories = data;
      if (this.setCategory) {
        this.category = this.setCategory;
      }
    },
    async getCountries() {
      this.noState = false;
      this.noCity = false;
      const {
        data: {
          data
        }
      } = await getLocations();
      this.countries = data;
      if (this.setCountry) {
        this.country = this.setCountry;
        await this.onCountryChange(this.country);
      }
    },
    async onCountryChange(selectedCountry) {
      this.states = [];
      this.cities = [];
      this.countryName = this.countries.filter(c => c.iso2 === selectedCountry)[0].name;
      const {
        data: {
          data
        }
      } = await getStates(selectedCountry);
      if (data.length < 1) {
        this.states.push(this.countryName);
        this.noState = true;
        this.country = selectedCountry;
        return;
      }
      this.states = data;
      this.country = selectedCountry;
      this.noState = false;
      if (this.setState) {
        this.state = this.setState;
        await this.onStateChange(this.state);
      }
    },
    async onStateChange(selectedState) {
      this.noCity = false;
      if (this.noState) {
        this.state = this.country;
        this.cities.push(this.countryName);
        return;
      }
      const {
        data: {
          data
        }
      } = await getCities(this.country, selectedState);
      if (data.length < 1) {
        this.noCity = true;
        this.stateName = this.states.filter(s => s.state_code === selectedState)[0].name;
        this.cities.push(this.stateName);
      } else {
        this.cities = data;
      }
      this.state = selectedState;
      if (this.setCity) {
        this.city = this.setCity;
      }
    },
    async onCityChange(selectedCity) {
      if (this.noState || this.noCity) {
        this.city = selectedCity;
        return;
      }
      this.city = selectedCity.name;
    },
    async onCategoryChange(selectedCategory) {
      this.category = selectedCategory;
    },
    async submitServiceRequestStaking() {
      const sufficientBalance = Number(this.amount) + Number(this.txWeight) <= Number(this.walletBalance);
      if (!sufficientBalance) {
        const error = await errorHandler("Insufficient Balance");
        this.errorTitle = error.title;
        this.errorMsg = error.message;
        this.showError = true;
        return;
      }
      this.isLoading = true;
      const country = this.country;
      const region = this.state;
      const city = this.city;
      const category = this.category;
      const status = "All";
      await this.$store.dispatch("lab/setCountryRegionCity", {
        country,
        region,
        city
      });
      const services = await this.$store.dispatch("lab/getServicesByCategory", {
        category,
        status
      });
      if (!services.length) {
        try {
          await createRequest(this.api, this.pair, this.country, this.state, this.city, this.category, this.amount);
        } catch (err) {
          const error = await errorHandler(err.message);
          this.errorTitle = error.title;
          this.errorMsg = error.message;
          this.showError = true;
          this.isLoading = false;
        }
      }
      if (services.length) {
        this.showErrorAlert = true;
        this.isLoading = false;
      }
    }
  }
};