//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from "vuex";
import { getLocations } from "@/common/lib/api";
import CryptoJS from "crypto-js";
import Kilt from "@kiltprotocol/sdk-js";
import { u8aToHex } from "@polkadot/util";
import { queryLastOrderHashByCustomer } from "@debionetwork/polkadot-provider";
import { createOrder } from "@/common/lib/polkadot-provider/command/order.js";
import Web3 from "web3";
export default {
  name: "ServiceDetailDialog",
  data: () => ({
    countries: [],
    publicKey: "",
    secretKey: "",
    loading: false,
    error: null
  }),
  async mounted() {
    await this.getCountries();
  },
  props: {
    show: Boolean
  },
  computed: {
    ...mapState({
      api: state => state.substrate.api,
      wallet: state => state.substrate.wallet,
      mnemonicData: state => state.substrate.mnemonicData,
      stakingData: state => state.lab.stakingData,
      selectedService: state => state.testRequest.products,
      polkadotWallet: state => state.substrate.polkadotWallet,
      lastEventData: state => state.substrate.lastEventData,
      usnBalance: state => state.substrate.usnBalance,
      usdtBalance: state => state.substrate.usdtBalance
    }),
    computeAvatar() {
      return this.selectedService.serviceImage ? this.selectedService.serviceImage : require("@/assets/debio-logo.png");
    },
    computeLongDescription() {
      if (this.selectedService.longDescription) {
        const description = this.selectedService.longDescription.split("||")[0];
        if (Web3.utils.isHex(description)) {
          return Web3.utils.hexToUtf8(description);
        }
        return description;
      }
      return "";
    }
  },
  watch: {
    lastEventData(event) {
      if (!event) return;
      if (event.method === "OrderCreated") this.toCheckout();
    }
  },
  methods: {
    async getCountries() {
      const {
        data: {
          data
        }
      } = await getLocations();
      this.countries = data;
    },
    async toCheckout() {
      const lastOrder = await queryLastOrderHashByCustomer(this.api, this.wallet.address);
      this.$router.push({
        name: "customer-request-test-checkout",
        params: {
          id: lastOrder
        }
      });
      this.loading = false;
    },
    getCustomerPublicKey() {
      const identity = Kilt.Identity.buildFromMnemonic(this.mnemonicData.toString(CryptoJS.enc.Utf8));
      this.publicKey = u8aToHex(identity.boxKeyPair.publicKey);
      this.secretKey = u8aToHex(identity.boxKeyPair.secretKey);
      return u8aToHex(identity.boxKeyPair.publicKey);
    },
    closeDialog() {
      this.$emit("close");
    },
    async getAssetId(currency) {
      currency === "USDTE" ? "USDT.e" : currency;
      let assetId = 0;
      const wallet = this.polkadotWallet.find(wallet => {
        var _wallet$currency;
        return (wallet === null || wallet === void 0 ? void 0 : (_wallet$currency = wallet.currency) === null || _wallet$currency === void 0 ? void 0 : _wallet$currency.toUpperCase()) === (currency === null || currency === void 0 ? void 0 : currency.toUpperCase());
      });
      assetId = wallet.id;
      return assetId;
    },
    async onSelect() {
      this.loading = true;
      const balance = this.usdtBalance;
      if (Number(this.selectedService.totalPrice) >= balance - 0.1) {
        this.error = {
          title: "Insufficient Balance",
          message: "Your transaction cannot go through because your account balance is too low or doesn't meet the minimum deposit needed. Please check your balance."
        };
        this.loading = false;
        return;
      }
      const customerBoxPublicKey = await this.getCustomerPublicKey();
      const assetId = await this.getAssetId(this.selectedService.currency === "USDTE" ? "USDT.e" : this.selectedService.currency);

      // try {
      //   const { orders } = await getOrderList();
      //   console.log("data is", orders.data)
      //   const handleDescription = async (service) => {
      //     const description = service.longDescription.split("||")[0];
      //     if (Web3.utils.isHex(description)) {
      //       return Web3.utils.hexToUtf8(description);
      //     }
      //     const regex = /^(https:\/\/ipfs.debio.network\/ipfs\/)/;
      //     if (regex.test(description)) {
      //       const response = await fetch(description);
      //       return await response.text();
      //     }

      //     return description;
      //   };

      //   for (const service of orders.data) {
      //     service.newDescription = await handleDescription(service);
      //   }
      // } catch (error) {
      //   console.error(error)
      // }

      await createOrder(this.api, this.wallet, this.selectedService.serviceId, this.selectedService.indexPrice, customerBoxPublicKey, "RequestTest", assetId);
    },
    country(country) {
      if (country) {
        return this.countries.filter(c => c.iso2 === country)[0].name;
      }
    },
    downloadFile() {
      window.open(this.selectedService.resultSample);
    }
  }
};